import React from 'react'

const Rewards = () => {

  return (
    <>
      <h2 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="headline--medium center-align">Artist Rewards Program</h2>
      <p className="center-align">
        The Reward Program is designed to celebrate you at every step throughout your journey as an Artist. With four different tiers of rewards, our goal is for every Artist
        to feel recognized for their hard work, commitment, and contributions. Thank you for making our community a place to help women recognize the beauty in themselves, in
        others, and as it exists all around them.
      </p>
    </>
  )
}

export default Rewards
